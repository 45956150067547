var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-actions',{attrs:{"id":"frmListData"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-card-actions',[(_vm.fShowFilter)?_c('v-spacer'):_vm._e(),(_vm.fShowFilter)?_c('v-text-field',{staticClass:"body-2",attrs:{"id":"edtFilter","append-icon":"mdi-filter","label":"Filtrar Dados Recuperados","single-line":"","hide-details":""},model:{value:(_vm.fFilter),callback:function ($$v) {_vm.fFilter=$$v},expression:"fFilter"}}):_vm._e()],1),_c('v-card-actions',[(_vm.fOriginOfRecords == _vm.fOriginPanelSearch && _vm.fShowFilter)?_c('div',{staticClass:"caption warning--text"},[_c('v-icon',{staticClass:"caption warning--text"},[_vm._v("mdi-alert")]),_vm._v(" Recuperados apenas dados resultado da PESQUISA ")],1):_vm._e()]),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"frmList","width":"984","height":"100%","headers":_vm.fHeaders,"items":_vm.fListOfRecords,"search":_vm.fFilter,"dense":true,"loading":_vm.fLoading,"loading-text":"Aguarde Recuperando os dados","calculate-widths":true,"footer-props":{
          itemsPerPageOptions: [8],
          showFirstLastPage: true,
          firstIcon: 'mdi-skip-previous-circle',
          lastIcon: 'mdi-skip-next-circle',
          prevIcon: 'mdi-arrow-left-drop-circle',
          nextIcon: 'mdi-arrow-right-drop-circle',
        }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.onGoDetailRecord(item)}}},[_c('v-icon',[_vm._v("mdi-account-box")])],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }